import {Row, Col, Container, Image, Ratio} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function FriendRequest() {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

	const [userFriendList, setData] = useState(null);

	
        useEffect(()=>{
		const token = localStorage.getItem('token');
                const fetchData = async() => {
                        const response = await axios.post(`${apiUrl}/FriendList`, {myToken : token});
			console.log('friendList: ', response.data);
                        await setData(response.data);
                };
                fetchData();
        },[]);

	let viewFriendList = null;
        let numberOfFriendList = 0;

	if(userFriendList) {
	//친구 신청 총 인원 표현
		viewFriendList = userFriendList.map((v) => (
		    <Row style={{marginBottom: '10px', paddingLeft: 0}} className='justify-content-center align-items-center'>
			<Link to={'/friendProfile/' + v.uid} className='justify-content-center align-items-center' style={{display:'contents',textDecoration:'none', color:"black"}}>
			<Col xs={2} style={{padding: '3px'}}>
			  <Ratio aspectRatio="1x1">
			    <Image src={v.pimage} roundedCircle fluid />
			  </Ratio>
			</Col>
			<Col xs={9} style={{paddingLeft: "10px"}}>
			  <div>
			    <Row><b>{v.nname}</b></Row>
			    <Row style={{ paddingLeft: '0'}}>
				<Col style={{overflow: 'hidden', textOverflow : 'ellipsis', whiteSpace: 'nowrap', color: '#787878'}} xs={12}>
					    {v.message? v.message: '\u00A0'}
				</Col>
			    </Row>
			  </div> 
			</Col>
			</Link>
		    </Row>
		));
		numberOfFriendList = viewFriendList.length;
	}

	return (
	<div style={{padding: '10px',marginTop: "10px"}}>
		<h3>
		  <b>친구목록</b>
		    <span style={{fontSize: 'calc(0.9rem + 0.1vw)', marginLeft: '5px'}}> {numberOfFriendList}명</span>
		</h3>
            <Container fluid="md" style={{textAlign: 'left', padding: 0}}>
              <Row style={{margin:0}}>
		{viewFriendList && viewFriendList.length > 0 ? (
			viewFriendList
		) : (
		    <div style={{textAlign:'center', paddingTop: '3rem', padding: '3rem', margin: '3% 10%', width: '80%', borderRadius: '1rem'}}>
			<Image src='/noFriend.png' style={{maxWidth: '30%', opacity: 0.5}} />
			<p style={{marginTop: '1rem', color: '#999999'}}>친구를 추가해보세요</p>
		    </div>
		)}
              </Row>
            </Container>
	  <br/><br/>
	</div>
	);
}

export default FriendRequest;

// Loading.js
import React, { useEffect, useState } from 'react';

const Loading = ({ loading }) => {
  const [showReloadButton, setShowReloadButton] = useState(false);

  useEffect(() => {
    if (loading) {
      // 3초 후에 버튼을 표시
      const timer = setTimeout(() => {
        setShowReloadButton(true);
      }, 3000);

      // 컴포넌트가 언마운트될 때 타이머를 클리어
      return () => clearTimeout(timer);
    } else {
      // 로딩이 끝나면 버튼을 숨김
      setShowReloadButton(false);
    }
  }, [loading]);

  const handleReload = () => {
    window.location.reload();
  };

  return loading ? (
    <div style={overlayStyle}>
      <div style={spinnerStyle}></div>
      {showReloadButton && (
	<div style={{position: 'absolute'}}>
        <button style={buttonStyle} onClick={handleReload}>
          새로고침
        </button>
	</div>
      )}
    </div>
  ) : null;
};

const overlayStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // 투명한 배경
  zIndex: 9999, // 다른 요소보다 위에 배치
};

const spinnerStyle = {
  width: '50px',
  height: '50px',
  border: '5px solid #f3f3f3', // 스피너의 바깥 테두리
  borderTop: '5px solid #3498db', // 스피너의 회전 부분
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
};

const spinnerAnimation = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// 애니메이션을 페이지 스타일에 추가
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(spinnerAnimation, styleSheet.cssRules.length);

const buttonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#3498db',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  fontSize: '0.6rem',
  cursor: 'pointer',
  zIndex: 10001, // 스피너 위에 표시
};

export default Loading;

